import React, { useState, useEffect } from "react";
import "./ShopPage.css";
import * as XLSX from "xlsx";

const ShopPage = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [sortType, setSortType] = useState("Relevance");
  const [filters, setFilters] = useState({
    genre: "",
    artist: "",
    availability: "",
    minPrice: "",
    maxPrice: "",
  });
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [handcraftProducts, setHandcraftProducts] = useState([]);
  const [cleaningKits, setCleaningKits] = useState([]);
  const [cds, setCds] = useState([]); // CDs products
  const [genres, setGenres] = useState([]);
  const [artists, setArtists] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(8);

  useEffect(() => {
    const fetchRecordsFromExcel = async () => {
      try {
        const response = await fetch("./Catalog.xlsx");
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        const sheetName = "Catalog";
        const worksheet = workbook.Sheets[sheetName];

        if (!worksheet) {
          throw new Error(`Sheet with name "${sheetName}" not found`);
        }

        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const processedProducts = jsonData.map((row, idx) => {
          const originalPrice = row.Price;
          let discountedPrice = originalPrice;

          // Apply discounts based on price range
          if (originalPrice >= 30 && originalPrice <= 40) {
            discountedPrice = originalPrice - 5;
          } else if (originalPrice >= 41 && originalPrice <= 79) {
            discountedPrice = originalPrice - 10;
          } else if (originalPrice >= 80 && originalPrice <= 200) {
            discountedPrice = originalPrice - 15;
          }

return{
          id: idx + 1,
          name: row.Name,
          artist: row.Artist,
          genre: row.Genre,
          price: discountedPrice,
          originalPrice: originalPrice, // Store the original price to display it later
          availability: row.Availability,
          year: row.Year,
          condition: row.New,
          imageUrl: row["Image URL"],
}
        });

        setProducts(processedProducts);
        setGenres([...new Set(processedProducts.map((product) => product.genre))]);
        setArtists([...new Set(processedProducts.map((product) => product.artist))]);

        setLoading(false);
      } catch (error) {
        console.error("Error loading Catalog Excel file: ", error);
        setLoading(false);
      }
    };

    const fetchHandcraftFromExcel = async () => {
      try {
        const response = await fetch("./Handcraft.xlsx");
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        const sheetName = "Handcraft";
        const worksheet = workbook.Sheets[sheetName];

        if (!worksheet) {
          throw new Error(`Sheet with name "${sheetName}" not found`);
        }

        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const processedHandcraftProducts = jsonData.map((row, idx) => {
          const originalPrice = row.Price;
          let discountedPrice = originalPrice;

          // Apply discounts based on price range
          if (originalPrice >= 30 && originalPrice <= 40) {
            discountedPrice = originalPrice - 5;
          } else if (originalPrice >= 41 && originalPrice <= 79) {
            discountedPrice = originalPrice - 10;
          } else if (originalPrice >= 80 && originalPrice <= 200) {
            discountedPrice = originalPrice - 15;
          }

          return{
          
          id: idx + 1,
          name: row.Name,
          artist: row.Artist,
          genre: row.Genre,
          price: discountedPrice,
          originalPrice: originalPrice,
          availability: row.Availability,
          year: row.Year,
          condition: row.New,
          imageUrl: row["Image URL"],
      }});

        setHandcraftProducts(processedHandcraftProducts);
      } catch (error) {
        console.error("Error loading Handcraft Excel file: ", error);
      }
    };

    const fetchCleaningKitsFromExcel = async () => {
      try {
        const response = await fetch("./CleaningKits.xlsx");
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        const sheetName = "CleaningKits";
        const worksheet = workbook.Sheets[sheetName];

        if (!worksheet) {
          throw new Error(`Sheet with name "${sheetName}" not found`);
        }

        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const processedCleaningKits = jsonData.map((row, idx) => {
          const originalPrice = row.Price;
          let discountedPrice = originalPrice;

          // Apply discounts based on price range
          if (originalPrice >= 30 && originalPrice <= 40) {
            discountedPrice = originalPrice - 5;
          } else if (originalPrice >= 41 && originalPrice <= 79) {
            discountedPrice = originalPrice - 10;
          } else if (originalPrice >= 80 && originalPrice <= 200) {
            discountedPrice = originalPrice - 15;
          }

          return {
          id: idx + 1,
          name: row.Name,
          price: discountedPrice,
          originalPrice: originalPrice,
          availability: row.Availability,
          imageUrl: row["Image URL"],
        }});

        setCleaningKits(processedCleaningKits);
      } catch (error) {
        console.error("Error loading Cleaning Kits Excel file: ", error);
      }
    };

    // Fetch CDs data
    const fetchCdsFromExcel = async () => {
      try {
        const response = await fetch("./CDs.xlsx");
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        const sheetName = "CDs";
        const worksheet = workbook.Sheets[sheetName];

        if (!worksheet) {
          throw new Error(`Sheet with name "${sheetName}" not found`);
        }

        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const processedCds = jsonData.map((row, idx) => {

          const originalPrice = row.Price;
          let discountedPrice = originalPrice;

          // Apply discounts based on price range
          if (originalPrice >= 30 && originalPrice <= 40) {
            discountedPrice = originalPrice - 5;
          } else if (originalPrice >= 41 && originalPrice <= 79) {
            discountedPrice = originalPrice - 10;
          } else if (originalPrice >= 80 && originalPrice <= 200) {
            discountedPrice = originalPrice - 15;
          }

          return{
          id: idx + 1,
          name: row.Name,
          artist: row.Artist,
          genre: row.Genre,
          price: discountedPrice,
          originalPrice: originalPrice,
          availability: row.Availability,
          year: row.Year,
          condition: row.New,
          imageUrl: row["Image URL"],
      }});

        setCds(processedCds);
      } catch (error) {
        console.error("Error loading CDs Excel file: ", error);
      }
    };

    fetchRecordsFromExcel();
    fetchHandcraftFromExcel();
    fetchCleaningKitsFromExcel();
    fetchCdsFromExcel(); // Fetch CDs data
  }, []);

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset pagination to the first page when a new category is selected
  };

  const handleBackToCategories = () => {
    setSelectedCategory(null);
  };

  const redirectToInstagram = () => {
    window.open("https://instagram.com/thunder_time_records", "_blank");
};



  const sendEmail = (productName) => {
    window.location.href = `mailto:thundertimerecords@gmail.com?subject=Inquiry about ${encodeURIComponent(productName)}&body=Hi, I'm interested in the product: ${encodeURIComponent(productName)}`;
  };

  const applyFilters = (product) => {
    let match = true;

    // Filtrare după gen muzical
    if (filters.genre && product.genre !== filters.genre) {
      match = false;
    }

    // Filtrare după artist
    if (filters.artist && product.artist !== filters.artist) {
      match = false;
    }

    // Filtrare după disponibilitate (In Stock sau Out of Stock)
    if (filters.availability) {
      if (filters.availability === "In Stock" && product.availability !== "Yes") {
        match = false;
      }
      if (filters.availability === "Out of Stock" && product.availability !== "No") {
        match = false;
      }
    }

    // Filtrare după preț minim
    if (filters.minPrice && product.price < filters.minPrice) {
      match = false;
    }

    // Filtrare după preț maxim
    if (filters.maxPrice && product.price > filters.maxPrice) {
      match = false;
    }

    return match;
  };

  const applySort = (products) => {
    if (sortType === "Price Ascending") {
      return [...products].sort((a, b) => a.price - b.price);
    } else if (sortType === "Price Descending") {
      return [...products].sort((a, b) => b.price - a.price);
    } else if (sortType === "Alphabetically Ascending") {
      return [...products].sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortType === "Alphabetically Descending") {
      return [...products].sort((a, b) => b.name.localeCompare(a.name));
    }
    return products;
  };

  const getCategoryProducts = () => {
    if (selectedCategory === "Records") {
      return products;
    } else if (selectedCategory === "Handcrafted by Thunder Time") {
      return handcraftProducts;
    } else if (selectedCategory === "Cleaning Kits") {
      return cleaningKits;
    } else if (selectedCategory === "CDs") {
      return cds;
    }
    return products;
  };

  const filteredProducts = applySort(getCategoryProducts().filter(applyFilters));

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getConditionColor = (condition) => {
    switch (condition) {
      case "Good":
        return "orange";
      case "Very Good":
        return "purple";
      case "New":
        return "green";
      case "Almost New":
        return "lightblue";
      default:
        return "gray";
    }
  };

  if (loading) {
    return <div>Loading products...</div>;
  }

  if (!selectedCategory) {
    return (
      <div className="category-container">
        <h1>Categories</h1>
        <div className="category-buttons">
  <div className="category" onClick={() => handleCategoryClick("Records")}>
    <div className="category-overlay">
      <h2>Vinyl Records</h2>
    </div>
  </div>
  <div className="category" onClick={() => handleCategoryClick("CDs")}>
    <div className="category-overlay">
      <h2>CDs</h2>
    </div>
  </div>
  <div className="category" onClick={() => handleCategoryClick("Handcrafted by Thunder Time")}>
    <div className="category-overlay">
      <h2>Handcrafted by Thunder Time</h2>
    </div>
  </div>
  <div className="category" onClick={() => handleCategoryClick("Cleaning Kits")}>
    <div className="category-overlay">
      <h2>Cleaning Kits</h2>
    </div>
  </div>
</div>

      </div>
    );
  }

  return (
    <div className="shop-page">
      <div className="sort-filter-container">
        <button className="back-button" onClick={handleBackToCategories}>
          Back to Categories
        </button>
        <div className="sort-filter">
          <select
            value={sortType}
            onChange={(e) => setSortType(e.target.value)}
          >
            <option>Relevance</option>
            <option>Alphabetically Ascending</option>
            <option>Alphabetically Descending</option>
            <option>Price Ascending</option>
            <option>Price Descending</option>
          </select>
        </div>
        <div className="pagination-control">
          <label>Products per page: </label>
          <select
            value={productsPerPage}
            onChange={(e) => setProductsPerPage(Number(e.target.value))}
          >
            <option value={4}>4</option>
            <option value={8}>8</option>
            <option value={12}>12</option>
          </select>
        </div>
      </div>

      {isFilterVisible && (
        <div className={`filter-panel ${isFilterVisible ? "visible" : "hidden"}`}>
          <h2>Filter</h2>
          <div>
            <label>Music Genre</label>
            <select name="genre" value={filters.genre} onChange={handleFilterChange}>
              <option value="">All</option>
              {genres.map((genre) => (
                <option value={genre} key={genre}>
                  {genre}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Artist</label>
            <select name="artist" value={filters.artist} onChange={handleFilterChange}>
              <option value="">All</option>
              {artists.map((artist) => (
                <option value={artist} key={artist}>
                  {artist}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Availability</label>
            <select
              name="availability"
              value={filters.availability}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              <option value="In Stock">In Stock</option>
              <option value="Out of Stock">Out of Stock</option>
            </select>
          </div>

          <div>
            <label>Price Range</label>
            <input
              type="number"
              name="minPrice"
              placeholder="Min"
              value={filters.minPrice}
              onChange={handleFilterChange}
            />
            <input
              type="number"
              name="maxPrice"
              placeholder="Max"
              value={filters.maxPrice}
              onChange={handleFilterChange}
            />
          </div>
        </div>
      )}

      <div
        className={`toggle-filter ${isFilterVisible ? "visible" : "hidden"}`}
        onClick={toggleFilter}
      >
        {isFilterVisible ? "◄" : "►"}
      </div>

      <div className={`shop-content ${!isFilterVisible ? "filter-hidden" : ""}`}>
        {currentProducts.length > 0 ? (
          <div className="product-list">
            {currentProducts.map((product) => (
              <div className="product-card" key={product.id}>
                <div className="product-image">
                  <img src={product.imageUrl} alt={product.name} />
                  {product.availability === 'No' && (
                    <div className="sold-overlay">SOLD</div>
                  )}
                  <div
                    className="condition-badge"
                    style={{ backgroundColor: getConditionColor(product.condition) }}
                  >
                    {product.condition}
                  </div>
                </div>
                <div className="product-info">
                  <h3>{product.name}</h3>
                  {product.artist && <p>{product.artist}</p>}
                  {product.genre && <p className="genre">{product.genre}</p>}
                  {product.year && <p>{product.year}</p>}
                  <p>
                        {/* Show the old price with strikethrough if there is a discount */}
                        {product.price < product.originalPrice && (
                          <span className="old-price">RON{product.originalPrice.toFixed(2)}</span>
                        )}
                        <span className="new-price">
                          RON{product.price.toFixed(2)}
                        </span>
                      </p>
                  <button onClick={() => redirectToInstagram(product.name)}>
                    <span>Contact us via</span>
                    <span>Instagram</span>
                  </button>
                  <button onClick={() => sendEmail(product.name)}>
                    <span>Contact us via</span>
                    <span>Email</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>No results found; please adjust filters or try another category.</div>
        )}

        {/* Pagination controls */}
        {totalPages > 1 && (
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopPage;
